import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, useNavigate, useParams, useLocation } from "react-router-dom";
import Layout from "../Layout";
import AddSpaceModal from "../../components/AddSpaceModal/AddWorkSpcModal";
import AddInsightModal from "../../components/AddInsightModal/AddInsightModal";
import AddChannelModal from "../../components/AddChannelModal/AddChannelModal";
import DeleteSpaceModal from "../../components/DeleteSpaceModal/DeleteSpaceModal";
import DeleteChannelModal from "../../components/DeleteChannelModal/DeleteChannelModal";
import DeleteInsightModal from "../../components/DeleteInsightModal/DeleteInsightModal";
import ShareInsightModal from "../../components/ShareInsightModal/ShareInsightModal";
import SchedulerModal from "../../components/SchedulerModal/SchedulerModal";
import InsightDetails from "./components/InsightDetails";
import InsightList from "./components/InsightList";
import Sidebar from "../../components/Sidebar/Sidebar";
import DashboardPage from "../dashboard/Dashboard";
import { useFetchData } from "../../hooks/useFetchData";
import { Context } from "../../Context/Context";
import { AiOutlineHome, AiOutlineInbox } from "react-icons/ai";
import { MdOutlineDashboard, MdOutlineInsights } from "react-icons/md";
import { Loader2 } from "lucide-react";
import ChatPage from "./components/ChatPage";
import './home.module.css';
import RightSidebar from "../../components/RightSidebar/RightSidebar";
import SmartSpaceModal from "../../components/AddSmartSpace/AddSmartSpace";
import Notes from "./components/Notes";
import { motion } from 'framer-motion';
import PdfViewer from "../../components/PdfViewerPage/PdfViewerPage";
import { RxCross2 } from "react-icons/rx";


const Home = () => {
    const { open, openInsight, darkTheme, activeSection, setActiveSection, showChat, channelData, list, setPathIndex, actionState, setActionState } = useContext(Context);
    const [loading, setLoading] = useState(false);
    const [conversationData, setConversationData] = useState(null);
    const [selectedThreadId, setSelectedThreadId] = useState(null);


    const navigate = useNavigate();
    const location = useLocation();
    const { spaceId, channelId, insightId } = useParams();

    const variants = {
        initial: { x: "100%", opacity: 0 }, // Start from the left, hidden
        animate: { x: 0, opacity: 1 }, // Move to its normal position, fully visible
        exit: { x: "100%", opacity: 0 }, // Move back to the left, hidden
    };

    useFetchData(setLoading, spaceId, channelId, insightId);
    useEffect(() => {
        switch (location.pathname) {
            case '/home':
                setActiveSection(0);
                break;
            case '/dashboard':
                setActiveSection(1);
                break;
            default:
                setActiveSection(0); // Default fallback
                break;
        }
    }, [location.pathname]);

    const handleSectionChange = (index) => {
        setActiveSection(index);
    };
    useEffect(() => {
        if (!loading && (location.pathname === '/home' || location.pathname === '/home/')) {
            if (channelData && list.length > 0) {
                const defaultChannelId = channelData.channel_id;
                const defaultSpaceId = channelData.space_id;
                const defaultInsightId = list[0].id;
                setPathIndex(prevPathIndex => ({
                    ...prevPathIndex,
                    workspace_id: defaultSpaceId,
                    channel_id: defaultChannelId,
                    insight_id: defaultInsightId
                }));
                navigate(`/home/sp/${defaultSpaceId}/ch/${defaultChannelId}/in/${defaultInsightId}`, { replace: true });
            } else if (channelData) {
                const defaultChannelId = channelData.channel_id;
                const defaultSpaceId = channelData.space_id;

                setPathIndex(prevPathIndex => ({
                    ...prevPathIndex,
                    workspace_id: defaultSpaceId,
                    channel_id: defaultChannelId,
                }));

                navigate(`/home/sp/${defaultSpaceId}/ch/${defaultChannelId}`, { replace: true });
            } else {
                console.warn("No channels or insights available.");
            }
        }
        else if (location.pathname !== '/home' && location.pathname !== '/home/') {
            setPathIndex(prevPathIndex => ({
                ...prevPathIndex,
                workspace_id: spaceId,
                channel_id: channelId,
                insight_id: insightId
            }));
        }
    }, [loading, location.pathname, channelData, list]);

    return (
        <Layout>
            <AddSpaceModal />
            <AddChannelModal />
            <AddInsightModal />
            <DeleteSpaceModal />
            <DeleteChannelModal />
            <DeleteInsightModal insightId={insightId} />
            <ShareInsightModal />
            <SmartSpaceModal />
            <SchedulerModal />
            {loading ? (
                <div className={`h-fit w-[100%] px-4 py-6 mt-8 sm:mt-10 transition-all duration-300 rounded-tl-lg overflow-none custom-scrollbar hide-scrollbar border-t  ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa] border-[#00000016]"}`}>
                    <div className="flex justify-center items-center h-screen">
                        <Loader2 className={`w-12 h-12 animate-spin ${darkTheme ? 'text-gray-300' : 'text-blue-500'}`} />
                    </div>
                </div>
            ) : (
                <div className="flex flex-grow z-[10] overflow-hidden mt-8 sm:mt-10 h-screen">
                    <div className={`hidden sm:block z-[10]  ${actionState === null ? "" : "opacity-[10%]"}`}>
                        <Sidebar spaceId={spaceId} channelId={channelId} insightId={insightId} />
                    </div>
                    {actionState === null ? (
                        <motion.div
                            key={`${location.pathname}-${actionState}`} // Dynamic key to force re-animation
                            className={`hidden sm:flex flex-grow border-t z-[9] ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"}`}
                        >
                            <>
                                <div
                                    className={`relative ${open ? `sm:ml-[230px] md:ml-[238px] lg:ml-[275px] xl:ml-[275px] 3xl:ml-[308px]` : "sm:ml-[108px] 3xl:ml-[116px]"} h-full`}
                                >
                                    <InsightList spaceId={spaceId} channelId={channelId} insightId={insightId} />
                                </div>

                                <div className="flex-grow h-full">
                                    <InsightDetails />
                                </div>
                            </>
                        </motion.div>
                    ) : (
                        <motion.div
                            key={`${location.pathname}-0`} // Dynamic key to force re-animation
                            className={`hidden sm:flex flex-grow border-t z-[10] ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"}`}
                            variants={variants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 0.2 }}
                        >
                            <>
                                <div
                                    className={`flex h-full w-[47%] ml-[115px] z-[9] rounded-xl border-l ${darkTheme ? "border-[#ffffff16] shadow-left-dark" : "border-[#00000016] shadow-left-light"}`}
                                >
                                    <div className="w-[100%] z-[9] rounded-xl">
                                        <InsightDetails />
                                    </div>
                                </div>

                                <div className="flex-grow h-full">
                                    {actionState === 0 ? (
                                        <div className="flex-grow h-full w-[100%] mx-auto z-[9]">
                                            <Notes />
                                        </div>
                                    ) : (
                                        <div className="flex h-full w-[100%] mx-auto z-[9] sm:max-w-[790px] 3xl:max-w-[1400px] 4xl:max-w-[1500px]">
                                            <ChatPage
                                                conversationData={conversationData}
                                                setConversationData={setConversationData}
                                                selectedThreadId={selectedThreadId}
                                                setSelectedThreadId={setSelectedThreadId}
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        </motion.div>
                    )}

                    <div className={`sm:hidden w-screen flex-grow p-2`}>
                        {activeSection === 0 && <Sidebar />}
                        {activeSection === 1 && <DashboardPage />}
                        {activeSection === 2 && <InsightList />}
                        {activeSection === 3 && <InsightDetails />}
                    </div>
                </div>
            )}
            <div className={`fixed z-[12] bottom-0 w-full sm:hidden border ${darkTheme ? "bg-[#181818] border-gray-700" : "bg-white border-[#00000016]"}`}>
                <div className={`grid grid-cols-4 gap-1 py-1 px-1`}>
                    <button
                        className={`flex justify-center rounded-lg p-4 ${activeSection === 0 ? (darkTheme ? "bg-[#2d2d2e]" : "bg-[#e8e8e9]") : (darkTheme ? "text-white" : "text-black")}`}
                        onClick={() => {
                            navigate('/home');
                            handleSectionChange(0);
                        }}
                    >
                        <AiOutlineHome />
                    </button>
                    <button
                        className={`flex justify-center rounded-lg p-4 ${activeSection === 1 ? (darkTheme ? "bg-[#2d2d2e]" : "bg-[#e8e8e9]") : (darkTheme ? "text-white" : "text-black")}`}
                        onClick={() => {
                            navigate('/dashboard');
                            handleSectionChange(1);
                        }}
                    >
                        <MdOutlineDashboard />
                    </button>
                    <button
                        className={`flex justify-center rounded-lg p-4 ${activeSection === 2 ? (darkTheme ? "bg-[#2d2d2e]" : "bg-[#e8e8e9]") : (darkTheme ? "text-white" : "text-black")}`}
                        onClick={() => {
                            navigate('/home');
                            handleSectionChange(2);
                        }}
                    >
                        <AiOutlineInbox />
                    </button>
                    <button
                        className={`flex justify-center rounded-lg p-4 ${activeSection === 3 ? (darkTheme ? "bg-[#2d2d2e]" : "bg-[#e8e8e9]") : (darkTheme ? "text-white" : "text-black")}`}
                        onClick={() => {
                            navigate('/home');
                            handleSectionChange(3);
                        }}
                    >
                        <MdOutlineInsights />
                    </button>
                </div>
            </div>
            {!loading && <RightSidebar darkTheme={darkTheme} />}
        </Layout>
    );
};

export default Home;


// import React, { useState, useEffect, useContext } from "react";
// import { Routes, Route, useNavigate, useParams, useLocation } from "react-router-dom";
// import Layout from "../Layout";
// import AddSpaceModal from "../../components/AddSpaceModal/AddWorkSpcModal";
// import AddInsightModal from "../../components/AddInsightModal/AddInsightModal";
// import AddChannelModal from "../../components/AddChannelModal/AddChannelModal";
// import DeleteSpaceModal from "../../components/DeleteSpaceModal/DeleteSpaceModal";
// import DeleteChannelModal from "../../components/DeleteChannelModal/DeleteChannelModal";
// import DeleteInsightModal from "../../components/DeleteInsightModal/DeleteInsightModal";
// import ShareInsightModal from "../../components/ShareInsightModal/ShareInsightModal";
// import SchedulerModal from "../../components/SchedulerModal/SchedulerModal";
// import InsightDetails from "./components/InsightDetails";
// import InsightList from "./components/InsightList";
// import Sidebar from "../../components/Sidebar/Sidebar";
// import DashboardPage from "../dashboard/Dashboard";
// import { useFetchData } from "../../hooks/useFetchData";
// import { Context } from "../../Context/Context";
// import { AiOutlineHome, AiOutlineInbox } from "react-icons/ai";
// import { MdOutlineDashboard, MdOutlineInsights } from "react-icons/md";
// import { Loader2 } from "lucide-react";
// import ChatPage from "./components/ChatPage";
// import './home.module.css';
// import RightSidebar from "../../components/RightSidebar/RightSidebar";
// import SmartSpaceModal from "../../components/AddSmartSpace/AddSmartSpace";

// const Home = () => {
//     const { open, openInsight, darkTheme, activeSection, setActiveSection, showChat, channelData, list, setPathIndex } = useContext(Context);
//     const [loading, setLoading] = useState(false);
//     const [conversationData, setConversationData] = useState(null);
//     const [selectedThreadId, setSelectedThreadId] = useState(null);
//     const [dummyState, setDummyState] = useState(true); // Dummy state for UI toggle
//     const [isDragging, setIsDragging] = useState(false);
//     const [dragPosition, setDragPosition] = useState(300); // Initial width of the draggable panel

//     const navigate = useNavigate();
//     const location = useLocation();
//     const { spaceId, channelId, insightId } = useParams();

//     useFetchData(setLoading, spaceId, channelId, insightId);

//     useEffect(() => {
//         switch (location.pathname) {
//             case '/home':
//                 setActiveSection(0);
//                 break;
//             case '/dashboard':
//                 setActiveSection(1);
//                 break;
//             default:
//                 setActiveSection(0);
//                 break;
//         }
//     }, [location.pathname]);

//     const insightListWidth = openInsight ? 450 : 120;

//     const handleSectionChange = (index) => {
//         setActiveSection(index);
//     };

//     useEffect(() => {
//         if (!loading && (location.pathname === '/home' || location.pathname === '/home/')) {
//             if (channelData && list.length > 0) {
//                 const defaultChannelId = channelData.channel_id;
//                 const defaultSpaceId = channelData.space_id;
//                 const defaultInsightId = list[0].id;
//                 setPathIndex(prevPathIndex => ({
//                     ...prevPathIndex,
//                     workspace_id: defaultSpaceId,
//                     channel_id: defaultChannelId,
//                     insight_id: defaultInsightId
//                 }));
//                 navigate(`/home/sp/${defaultSpaceId}/ch/${defaultChannelId}/in/${defaultInsightId}`, { replace: true });
//             } else if (channelData) {
//                 const defaultChannelId = channelData.channel_id;
//                 const defaultSpaceId = channelData.space_id;
//                 setPathIndex(prevPathIndex => ({
//                     ...prevPathIndex,
//                     workspace_id: defaultSpaceId,
//                     channel_id: defaultChannelId,
//                 }));
//                 navigate(`/home/sp/${defaultSpaceId}/ch/${defaultChannelId}`, { replace: true });
//             } else {
//                 console.warn("No channels or insights available.");
//             }
//         } else if (location.pathname !== '/home' && location.pathname !== '/home/') {
//             setPathIndex(prevPathIndex => ({
//                 ...prevPathIndex,
//                 workspace_id: spaceId,
//                 channel_id: channelId,
//                 insight_id: insightId
//             }));
//         }
//     }, [loading, location.pathname, channelData, list]);

//     return (
//         <Layout>
//             <AddSpaceModal />
//             <AddChannelModal />
//             <AddInsightModal />
//             <DeleteSpaceModal />
//             <DeleteChannelModal />
//             <DeleteInsightModal insightId={insightId} />
//             <ShareInsightModal />
//             <SmartSpaceModal />
//             <SchedulerModal />

//             {loading ? (
//                 <div className={`h-fit w-full px-4 py-6 mt-8 sm:mt-10 transition-all duration-300 rounded-tl-lg overflow-none custom-scrollbar hide-scrollbar border-t ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa] border-[#00000016]"}`}>
//                     <div className="flex justify-center items-center h-screen">
//                         <Loader2 className={`w-12 h-12 animate-spin ${darkTheme ? 'text-gray-300' : 'text-blue-500'}`} />
//                     </div>
//                 </div>
//             ) : (
//                 <div className="flex flex-grow z-9 overflow-hidden mt-8 sm:mt-10 h-screen">
//                     <div className={`hidden sm:flex flex-grow border-t z-9 ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"}`}>
//                         {dummyState ? (
//                             // Overlay UI when dummyState is true
//                             <div className="flex flex-grow relative transition-all duration-300">
//                                 <div
//                                     style={{ transform: "translateX(-50px)" }}
//                                     className={`transition-all duration-300 ${open ? "sm:ml-60 md:ml-64" : "sm:ml-32"}`}
//                                 >
//                                     <InsightList spaceId={spaceId} channelId={channelId} insightId={insightId} />
//                                 </div>
//                                 <div
//                                     className="flex-grow transition-transform duration-300 transform"
//                                     style={{ transform: "translateX(-100px)" }}
//                                 >
//                                     <InsightDetails />
//                                 </div>
//                                 <div className="w-80 h-full bg-opacity-80 bg-gray-900">
//                                     <RightSidebar darkTheme={darkTheme} />
//                                 </div>
//                             </div>
//                         ) : (
//                             // Default UI when dummyState is false
//                             <>
//                                 <div className="hidden sm:block">
//                                     <Sidebar spaceId={spaceId} channelId={channelId} insightId={insightId} />
//                                 </div>
//                                 <div
//                                     style={{ width: `${insightListWidth}px` }}
//                                     className={`relative ${open ? "sm:ml-60 md:ml-64" : "sm:ml-32"} h-full`}
//                                 >
//                                     <InsightList spaceId={spaceId} channelId={channelId} insightId={insightId} />
//                                 </div>
//                                 <div className="flex-grow h-full">
//                                     {showChat ? (
//                                         <ChatPage conversationData={conversationData} setConversationData={setConversationData} selectedThreadId={selectedThreadId} setSelectedThreadId={setSelectedThreadId} />
//                                     ) : (
//                                         <InsightDetails />
//                                     )}
//                                 </div>
//                             </>
//                         )}
//                     </div>

//                     <div className={`sm:hidden w-screen flex-grow p-2`}>
//                         {activeSection === 0 && <Sidebar />}
//                         {activeSection === 1 && <DashboardPage />}
//                         {activeSection === 2 && <InsightList />}
//                         {activeSection === 3 && <InsightDetails />}
//                     </div>
//                 </div>
//             )}

//             <div className={`fixed z-12 bottom-0 w-full sm:hidden border ${darkTheme ? "bg-[#181818] border-gray-700" : "bg-white border-[#00000016]"}`}>
//                 <div className="grid grid-cols-4 gap-1 py-1 px-1">
//                     {['Home', 'Dashboard', 'Inbox', 'Insights'].map((label, index) => (
//                         <button
//                             key={label}
//                             className={`flex justify-center rounded-lg p-4 ${activeSection === index ? (darkTheme ? "bg-[#2d2d2e]" : "bg-[#e8e8e9]") : (darkTheme ? "text-white" : "text-black")}`}
//                             onClick={() => {
//                                 navigate(`/${label.toLowerCase()}`);
//                                 handleSectionChange(index);
//                             }}
//                         >
//                             {[<AiOutlineHome />, <MdOutlineDashboard />, <AiOutlineInbox />, <MdOutlineInsights />][index]}
//                         </button>
//                     ))}
//                 </div>
//             </div>
//             {!loading && <RightSidebar darkTheme={darkTheme} />}
//         </Layout>
//     );
// };

// export default Home;

import React, { useContext, useEffect, useState, useCallback } from "react";
import { Button } from "antd";
import { Context } from "../../Context/Context";
import { useNavigate } from "react-router-dom";
import { Spotlight } from "../../components/ui/Spotlight";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "../../interceptors/auth.interceptor.js";
import FeatureSection from "../../components/FeatureSection/FeatureSection.jsx";
import { CiLogin } from "react-icons/ci";
import HeroSection from "../../components/HeroSection/Hero.jsx";
import { BsArrowReturnRight } from "react-icons/bs";

const LandingPage = () => {
  const navigate = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const redirectUri = "https://tensorweave.com/auth/google/callback";
  const features = [
    {
      title: "Memory for LLMs",
      description:
        "Enhance your language models with advanced memory management.",
    },
    {
      title: "Agent Development Environment",
      description: "Build and refine AI agents in a controlled environment.",
    },
    {
      title: "Prototype to Production",
      description:
        "Seamlessly transition from prototype to production-ready systems.",
    },
    {
      title: "White Box Systems",
      description: "Gain full visibility and control over your AI systems.",
    },
    {
      title: "Model Agnostic",
      description:
        "Work with any AI model, ensuring flexibility and adaptability.",
    },
  ];

  const handleGoogleLogin = useCallback(async () => {
    if (isAuthenticating) return;

    try {
      setIsAuthenticating(true);
      const response = await axiosInstance.get("/auth/login/google", {
        params: { redirect_uri: redirectUri },
      });
      const authUrl = response.data.authorization_url;
      const width = 500;
      const height = 600;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      window.open(
        authUrl,
        "googleAuthPopup",
        `width=${width},height=${height},left=${left},top=${top}`
      );
    } catch (error) {
      console.error("Error during Google login initiation:", error);
      setIsAuthenticating(false);
    }
    finally{
      setIsAuthenticating(false);
    }
  }, [redirectUri, isAuthenticating]);

  const handleAuthSuccess = useCallback(
    async (code) => {
      try {
        const response = await axiosInstance.get("/auth/auth/google", {
          params: { code, redirect_uri: redirectUri },
        });

        const accessToken = response.data.data.access_token;
        const decodedToken = jwtDecode(accessToken);

        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("user_email", decodedToken.email);
        localStorage.setItem("refresh_token", response.data.data.refresh_token);

        if(response.data.data.is_first_time){
          navigate("/onboarding")
        }
        else{
          navigate("/home");
        }
      } catch (error) {
        console.error("Error during Google authentication:", error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsAuthenticating(false);
      }
    },
    [navigate, redirectUri]
  );

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) return;

      if (event.data.type === "GOOGLE_AUTH_SUCCESS") {
        handleAuthSuccess(event.data.code);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [handleAuthSuccess]);

  const { darkTheme, setDarkTheme } = useContext(Context);

  useEffect(() => {
    setDarkTheme(true);
  }, [setDarkTheme]);

  const handleSignupClick = () => {
    navigate("/login");
  };

  return (
    <div
      className={`h-full ${darkTheme ? "bg-[#181818]" : "bg-gradient-to-b from-gray-50 to-gray-200"
        }`}
    >
      {/* <Spotlight
        className="-top-40 left-0 md:left-60 md:-top-20"
        fill="white"
      /> */}

      <header className="sm:px-[100px] 3xl:px-[200px] py-2 flex flex-row items-center justify-center sm:justify-between mx-auto border-b border-gray-500">
        <div className="flex items-center justify-center mb-4 sm:mb-0 ">
          <img
            src="/logo1.png"
            alt="Logo"
            width="60px"
            height="46px"
            className={`transition-transform ${darkTheme ? "" : "filter invert"
              } ${darkTheme ? "hover:scale-105" : ""}`}
          />
          <span
            className={`text-base font-bold ml-3 ${darkTheme ? "text-white" : "text-gray-900"
              }`}
          >
            Tensorweave
          </span>
        </div>
        <div className="xsm:mb-4 hidden sm:inline-block sm:mb-0">
          <p
            className="text-[14px] text-white mr-10 cursor-pointer pr-[3px] flex items-center hover:text-[#ADB5BD]"
            onClick={handleGoogleLogin}
          >
            <BsArrowReturnRight className="mr-2" /> {/* Add margin-right to space it from text */}
            Log in
          </p>
        </div>

      </header>

      <main className="mx-auto lg:flex-row items-center lg:items-center border-b border-gray-500">
        <div className="items-center text-center pt-4 sm:px-[100px] 3xl:px-[200px] mt-[-55px] border-b border-gray-500">
          <HeroSection darkTheme={darkTheme} handleGoogleLogin={handleGoogleLogin} />
        </div>
      </main>
      <div className="sm:py-8 sm:px-24 3xl:px-48">
        <FeatureSection darkThemec={darkTheme} />
      </div>
      <footer
        className={`mt-12 py-8 border-t ${
          darkTheme
            ? "border-[#ffffff16] text-gray-300"
            : "border-[#00000016] text-gray-600"
        }`}
      >
        <center className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div>
            <div className="md:mb-0">
              <h3
                className={`text-base font-semibold mb-4 ${
                  darkTheme ? "text-white" : "text-gray-900"
                }`}
              >
                About Us
              </h3>
              <p className="text-xs">
                Tensorweave is your AI-powered knowledge management solution,
                helping you organize and access information effortlessly.
              </p>
            </div>
          </div>
          <div
            className={`mt-8 pt-8 border-t ${
              darkTheme ? "border-[#ffffff16]" : "border-gray-200"
            } text-xs text-center`}
          >
            © {new Date().getFullYear()} Tensorweave. All rights reserved.
          </div>
        </center>
      </footer>
    </div>
  );
};

export default LandingPage;

import React, { useContext, useEffect, useState, useRef } from "react";
import { Breadcrumb } from 'antd';
import { Context } from '../../../Context/Context';
import Summary from "./Summary";
import { MdOutlineArrowUpward } from 'react-icons/md';
import { createChannelInsightThread, createThreadConversation, getThreadByChannelInsight } from "../../../services/chatService";
import { IoMdArrowRoundBack } from "react-icons/io";

const InsightDetails = () => {
    const { knowledgespace, pathIndex, darkTheme, allThreadData, setAllThreadData, summaryInfo, setShowChat, setMessages, setAnswerLoading, loadingInsight, insightDetails, actionState, setActionState, messages } = useContext(Context);
    const [path, setPath] = useState([]);
    const [message, setMessage] = useState("");
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current?.focus();
    });

    useEffect(() => {
        const updatePath = () => {
            let newPath = [];
            if (pathIndex?.workspace_id !== null) {
                const workspace = knowledgespace.find(
                    (ws) => ws.id === pathIndex.workspace_id
                );
                if (workspace) {
                    newPath.push(workspace.name);
                    if (pathIndex?.channel_id !== null) {
                        const channel = workspace.channels.find(
                            (ch) => ch.id === pathIndex?.channel_id
                        );
                        if (channel) {
                            newPath.push(channel.name);
                        }
                    }
                }
            }
            if(newPath.length > 0) setPath(newPath);
        };
        updatePath();
    }, [pathIndex]);
    const handleInputChangeMessage = (e) => {
        setMessage(e.target.value);
    };

    const handleSendMessage = async () => {
        try {
            if (message.trim() === '') return;
            setShowChat(true);
            setActionState(1);
            const currentMessage = message;
            const userMessage = { text: currentMessage, sender: 'user' };
            setMessages(prev => [...prev, userMessage]);
            setMessage('');
            setAnswerLoading(true);
            if (allThreadData && allThreadData?.length === 0) {
                const createResponse = await createChannelInsightThread(pathIndex.channel_id, pathIndex.insight_id);
                if (createResponse && createResponse?.data) {
                    const thread_data = await getThreadByChannelInsight(pathIndex.channel_id, pathIndex.insight_id);

                    if (thread_data?.data) {
                        setAllThreadData(thread_data?.data);
                        const response = await createThreadConversation(thread_data?.data[0].thread_id, currentMessage);
                        setTimeout(() => {
                            const botMessage = { text: response?.data.answer, sender: 'bot' };
                            setMessages(prev => [...prev, botMessage]);
                        }, 1000);
                    }
                }
            } else {
                const response = await createThreadConversation(allThreadData[0].thread_id, currentMessage);
                setTimeout(() => {
                    const botMessage = { text: response?.data.answer, sender: 'bot' };
                    setMessages(prev => [...prev, botMessage]);
                }, 1000);
            }
        } catch (error) {
            console.error("Error occurred:", error);
            throw error;
        }
        finally {
            setAnswerLoading(false);
        }
    };

    return (
        <div className={`flex flex-col h-full 2-full z-[8] border-r border-[1px] ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa]"}`}>
            {/* Header Breadcrumb */}
            <div className={` border-b ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa] border-[#00000016]"} `}>
                <div className={`flex flex-row justify-between items-center p-[1px] ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"}`}>
                    <div
                    className={`pl-2 cursor-pointer ${actionState === null ? "hidden" :""}`}
                        onClick={() => {
                            setActionState(null);
                        }}
                    >
                        <IoMdArrowRoundBack />
                    </div>

                    <div className="p-2 mx-2 min-h-[40px] flex-grow">
                        <Breadcrumb
                            separator={<span className={`${darkTheme ? "text-white" : "text-[#2f2f2f]"}`}>{">>"}</span>}
                            items={path.map((p, index) => ({
                                title: <span className={`${darkTheme ? "text-white" : "text-[#2f2f2f]"} font-semibold`}>
                                    {p.split(' ')[0].charAt(0).toUpperCase() + p.split(' ')[0].slice(1)}
                                </span>,
                                key: index,
                            }))}
                        />
                    </div>
                </div>
            </div>

            {/* Scrollable Summary */}
            <div className={`flex overflow-auto px-2 ${darkTheme ? 'dark-theme' : 'light-theme'} custom-scrollbar scroll-container`}>
                {summaryInfo !== null && <Summary />}
            </div>
            {summaryInfo !== null && !loadingInsight && insightDetails && actionState === null &&
                <div className={`flex justify-center sm:py-4 3xl:py-6 px-8 ${darkTheme ? '' : ''}`}>
                    <div className={`flex sm:w-[90%] 3xl:w-[65%] 3xll:w-[60%] 4xl:w-[70%] ${darkTheme ? "bg-[#323232] shadow-[0_0_3px_1px_rgba(59,140,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]" : "bg-white shadow-[0_0_3px_1px_rgba(59,130,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]"} pl-4 px-2 rounded-full py-1`}>
                        <input
                            type="text"
                            ref={inputRef}
                            value={message}
                            onChange={handleInputChangeMessage}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') handleSendMessage();
                            }}
                            className={`flex-grow sm:py-1 3xl:py-4 px-2 rounded-full justify-center items-center focus:outline-none ${darkTheme
                                ? 'bg-[#323232] text-white placeholder-gray-300 border-[#3d4144]'
                                : 'bg-[#fbfbfa] text-black placeholder-[#acacad] border-[#c7c7c7]'
                                }`}
                            placeholder="Chat with the insight..."
                        />
                        <button
                            onClick={handleSendMessage}
                            className={`sm:p-2 3xll:p-4  text-white rounded-full ${message?.length > 0 ? "bg-[#f15025] hover:bg-[#f15025]" : "bg-[#404040]"}`}
                        >
                            <MdOutlineArrowUpward className="h-6 w-6" />
                        </button>
                    </div>
                </div>}
        </div>
    );
};

export default InsightDetails;

import React, { useContext, useRef, useState } from 'react';
import { motion } from 'framer-motion'
import { Context } from '../../Context/Context.jsx';
import { RiCloseFill } from 'react-icons/ri';
import { Dialog, DialogPanel, DialogTitle, Transition } from '@headlessui/react';
import { createInsight, getInsightDetails } from '../../services/insightService.js';
import { icons } from "../icons.jsx";
import { Upload, Button } from 'antd';
import { ImSpinner2 } from 'react-icons/im';
import toast, { toastConfig } from 'react-simple-toasts';
import { HiArrowRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

const { Dragger } = Upload;
toastConfig({ theme: 'warning' });

const AddInsightModal = () => {
    const { showIssueModal, setShowIssueModal, pathIndex, setInsightDetails, setList, darkTheme, setPathIndex } = useContext(Context);
    const [uploadedUrl, setUploadedUrl] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);
    const navigate = useNavigate();

    const [errors, setErrors] = useState({}); // Error state

    const handleClose = () => {
        setShowIssueModal(false);
        setUploadedUrl("");
        setUploadedFiles([]);
        setErrors({});
    };

    function checkSource(url) {
        const check = (url.includes("youtube") || url.includes("youtu") || url.includes("youtu.be"));
        return check ? "youtube" : "web";
    }

    const handleDone = async () => {
        const newErrors = {};
        if (!uploadedUrl.trim() && uploadedFiles.length === 0) {
            newErrors.uploadedUrl = 'Please provide a URL.';
        }
        if (uploadedUrl && !isValidURL(uploadedUrl)) {
            newErrors.uploadedUrl = 'Please enter a valid URL.';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);
        handleClose();

        // Set up a promise for the loading toast
        const taskPromise = new Promise(async (resolve, reject) => {
            try {
                let finalUrl = uploadedUrl;
                if (uploadedFiles.length > 0) {
                    finalUrl = URL.createObjectURL(uploadedFiles[0]);
                }

                const response = await createInsight(pathIndex.channel_id, checkSource(finalUrl), finalUrl);
                if (!response || response.data.success === false) throw new Error("Failed to create insight.");

                const insight_data = await getInsightDetails(response.data.channels[0], response.data.insight_id);
                if (!insight_data || insight_data.data.success === false) throw new Error("Failed to fetch insight details.");

                if (insight_data?.data) {
                    setInsightDetails(insight_data?.data);
                }

                setList((prevData) => [
                    {
                        id: insight_data?.data.insight_id,
                        logo: insight_data?.data.source_data.logo,
                        title: insight_data?.data.source_data.title,
                        image: insight_data?.data.source_data.top_image,
                        description: insight_data?.data.summary.analysis.insights[0].points[0] || "No Description Available",
                        source: insight_data?.data.source,
                        url: insight_data?.data.url,
                        time: new Date().toISOString(),
                        icon: icons.network,
                    },
                    ...prevData,
                ]);

                if(insight_data?.data?.insight_id){ 
                    setPathIndex((prevIndex) => ({ ...prevIndex, insight_id: insight_data?.data.insight_id }));
                    console.log(insight_data?.data?.insight_id)
                }
                navigate(`/home/sp/${pathIndex.space_id}/ch/${pathIndex.channel_id}/in/${insight_data?.data.insight_id}`, { replace: true });

                resolve();  // Resolve the promise when the task is complete
            } catch (error) {
                console.error("Error creating insight:", error.message || error);
                reject(new Error("Error creating insights. Please try again!"));
            }
        });

        // Show toast with loading state
        const taskToast = toast("Creating Insight...", {
            position: 'top-center',
            theme: 'info',
            className: `${darkTheme ? "bg-black text-white" : "bg-white text-black"} py-3 px-4 rounded-lg flex items-center gap-2`,
            loading: taskPromise,
            duration: Infinity,
        });

        taskPromise
            .then(() =>
                taskToast.update({
                    message: '✅ Insight Created Successfully!',
                    position: 'top-center',
                    className: `${darkTheme ? "bg-black text-green-400" : "bg-white text-green-600"} py-3 px-4 rounded-lg flex items-center gap-2`,
                    style: {
                        border: "1px solid #4CAF50",
                        fontSize: "0.95rem",
                        fontWeight: "500",
                    },    
                    duration: 2000,
                    theme: 'success',
                })
            )
            .catch(() =>
                taskToast.update({message: "🚨 Error encountered while creating insights. Please try again!", 
                    position: 'top-center',
                    className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"} py-3 px-4 rounded-lg flex items-center gap-2`,
                    style: {
                        border: "1px solid #ff4d4d",
                        fontSize: "0.95rem",
                        fontWeight: "500",
                    },
                    duration: 2000,
                    icon: '🚨',
                })
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const isValidURL = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    const beforeUpload = (file) => {
        setUploadedFiles((prevData) => [...prevData, file]);
        setErrors(prev => ({ ...prev, uploadedUrl: undefined })); // Clear URL error if any
        return false; // Prevent automatic upload
    };
    const onKeyEnter = (event) => {
        if (event.key == 'Enter') {
            if (loading) {
                return;
            }
            handleDone();
            // console.log(uploadedUrl)
        }
    }

    return (
        <Transition appear show={showIssueModal} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" onClose={handleClose} initialFocus={inputRef}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className={`fixed inset-0 ${darkTheme ? "bg-black" : "bg-white"} bg-opacity-65 backdrop-blur-md`} aria-hidden="true" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className={`relative transform overflow-visible rounded-xl shadow-none transition-all sm:max-w-3xl w-full ${darkTheme
                                    ? 'text-white'
                                    : 'text-black'
                                    }`}
                            >
                                {/* Header */}
                                <p className={`text-[30px] py-4 ${darkTheme ? "text-gray-300" : ""}`}>Get detailed insights from any website</p>
                                <div className={`items-center rounded-xl w-[100%] border ${darkTheme ? "bg-[#000] border-[#505050]" : "bg-white"}`}>
                                    <div className="px-6 py-2">
                                        {errors.uploadedUrl && (
                                            <p className="text-left text-sm text-red-500 px-4">{errors.uploadedUrl}</p>
                                        )}
                                        {!errors.uploadedUrl && <p className="text-left pt-5 text-sm text-white-400"></p>}
                                        <input
                                            ref={inputRef}
                                            id="url"
                                            type="url"
                                            placeholder="Paste the URL and press enter"
                                            className={`w-full px-3 bg-transparent border-none outline-none resize-none text-base ${darkTheme ? "text-zinc-200 placeholder-zinc-500" : "text-zinc-800 placeholder-zinc-400"}`}
                                            value={uploadedUrl}
                                            onKeyDown={
                                                (e) => {
                                                    onKeyEnter(e);
                                                }
                                            }
                                            onChange={(e) => {
                                                setUploadedUrl(e.target.value);
                                                setErrors(prev => ({ ...prev, uploadedUrl: undefined }));
                                            }}
                                        />
                                        <p className="text-left pt-5 text-sm text-white-400"></p>


                                        {/* <input
                                            ref={inputRef}
                                            id="url"
                                            type="url"
                                            placeholder="Paste the URL and press enter"
                                            value={uploadedUrl}
                                            onKeyDown={
                                                (e) => {
                                                    onKeyEnter(e);
                                                }
                                            }
                                            onChange={(e) => {
                                                setUploadedUrl(e.target.value);
                                                setErrors(prev => ({ ...prev, uploadedUrl: undefined }));
                                            }}
                                            className={`w-full py-1 px-4 border rounded-md focus:outline-none ${errors.uploadedUrl ? 'border-red-500' : ''} ${darkTheme
                                                ? 'bg-[#1A1B1A] text-white placeholder-gray-600 border-[#3d4144] focus:shadow-[0_0_3px_1px_rgba(59,140,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]'
                                                : 'bg-[#fbfbfa] text-black placeholder-[#acacad] border-[#c7c7c7] focus:shadow-[0_0_3px_1px_rgba(59,130,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]'
                                                }`}
                                        />
 */}
                                    </div>
                                    <div className={`px-6 py-1 flex justify-end sm:px-6 border-t ${darkTheme ? "border-[#404040]" : ""}`}>
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center gap-2"></div>
                                            <button
                                                onClick={() => {
                                                    if (loading) {
                                                        return;
                                                    }
                                                    handleDone();
                                                }}
                                                className={`sm:p-2 3xll:p-4 rounded-full ${uploadedUrl?.length > 0 ? "text-[#f15025] hover:text-[#f15025]" : "text-[#404040]"}`}
                                            >
                                                <motion.div>
                                                    <HiArrowRight className="h-5 w-5" /> {/* Arrow pointing right */}
                                                </motion.div>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
export default AddInsightModal;

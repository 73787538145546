import React, { useContext, useState, useEffect } from 'react';
import { Loader2, Pencil, Trash2 } from 'lucide-react';
import Layout from '../Layout';
import { Context } from '../../Context/Context';
import { getAllJobs, updateChannelJob } from '../../services/schedulerJobService';
import DeleteJobModal from '../../components/DeleteJobModal/DeleteJobModal';
import { CiPause1, CiPlay1 } from "react-icons/ci";
import toast from 'react-simple-toasts';


export default function SchedulerPage() {
    const { darkTheme } = useContext(Context);
    const [showDeleteJobModal, setShowDeleteJobModal] = useState({ show: false, job_id: null });
    const [jobs, setJobs] = useState([]);
    return (
        <Layout>
            <DeleteJobModal showDeleteJobModal={showDeleteJobModal} setShowDeleteJobModal={setShowDeleteJobModal} setJobs={setJobs} />
            <div className={`relative flex flex-grow mt-0 sm:mt-10 border-t border sm:ml-[50px] h-full ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa] border-[#00000016]"}`}>
                <SchedulerPageInner jobs={jobs} setJobs={setJobs} setShowDeleteJobModal={setShowDeleteJobModal} />
            </div>
        </Layout>
    )
}

function SchedulerPageInner({ jobs, setJobs, setShowDeleteJobModal }) {
    const { darkTheme } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [statusFilter, setStatusFilter] = useState('all');
    const [nameFilter, setNameFilter] = useState('');

    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = async () => {
        setLoading(true);
        try {
            const response = await getAllJobs();
            setJobs(response.data.reverse());
        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
        setLoading(false);
    };
    const handleStatusToggle = async (job_id, status) => {
        try {
            const updatedJobDetails = {
                "status": status === 'active' ? 'pause' : 'active',
            };
            const response = await updateChannelJob(job_id, updatedJobDetails);

            if (response?.success) {
                // Update the job's status in the local state
                setJobs(prevJobs =>
                    prevJobs.map(job =>
                        job.job_id === job_id ? { ...job, status: updatedJobDetails.status } : job
                    )
                );

                toast(`Job ${status === 'active' ? 'paused' : 'activated'} successfully`, {
                    position: 'top-center',
                    className: `${darkTheme ? "bg-black text-green-400 shadow-[0_4px_10px_rgba(150,150,150,0.15)]" : "bg-white text-green-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                    style: {
                        border: "1px solid #00cc66",
                        fontSize: "0.95rem",
                        fontWeight: "500",
                    },
                    clickClosable: true,
                    icon: status === 'active' ? '⏸️' : '▶️',
                });
            }
        } catch (error) {
            console.log(error.message);
            toast("🚨 Error: Update was Unsuccessful. Please try again.", {
                position: 'top-center',
                className: `${darkTheme ? "bg-black text-red-400 shadow-[0_4px_10px_rgba(150,150,150,0.15)]" : "bg-white text-red-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                style: {
                    border: "1px solid #ff4d4d",
                    fontSize: "0.95rem",
                    fontWeight: "500",
                },
                clickClosable: true,
                icon: '🚨',
            });
        }
    };
    const formatFrequency = (frequency) => {
        // Separate the number and the unit using a regular expression
        const [, number, unit] = frequency.match(/(\d+)([a-zA-Z]+)/) || [];
        if (!number || !unit) return frequency; // Return original if there's no match

        // Pluralize the unit if the number is greater than 1
        const formattedUnit = unit === 'day' || unit === 'week' ? (number > 1 ? `${unit}s` : unit) : unit;
        return `${number} ${formattedUnit}`;
    };

    const filteredJobs = jobs.filter(job =>
        (statusFilter === 'all' || job.status === statusFilter) &&
        job.name.toLowerCase().includes(nameFilter.toLowerCase())
    );

    return (
        <div className={`px-4 py-4 rounded-xl hide-scrollbar justify-center w-screen ${darkTheme ? "bg-[#181818] border-[#ffffff16] text-gray-300" : "bg-[#fafbfa] text-gray-800"}`}>
            <h1 className="text-xl font-bold mb-8 pl-4">Your Scheduled Jobs</h1>

            {loading ? (
                <div className="flex justify-center items-center h-32">
                    <Loader2 className="animate-spin" size={24} />
                </div>
            ) : (
                <div className={`overflow-x-auto border ${darkTheme ? "border-gray-500" : "border-gray-600"} rounded-xl hide-scrollbar`}>
                    <table className={`w-full divide-y ${darkTheme ? "divide-gray-500" : "divide-gray-600"} shadow-sm rounded-xl`}>
                        <thead>
                            <tr className="">
                                <th className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-center">Job Name</th>
                                <th className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-center">Frequency</th>
                                <th className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-center">Status</th>
                                <th className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-center">Keywords</th>
                                <th className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody className={`divide-y ${darkTheme ? "divide-gray-500" : "divide-gray-600"}`}>
                            {filteredJobs.map((job) => (
                                <tr key={job.job_id}>
                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-center">{job.name}</td>
                                    <td className="px-4 py-4 whitespace-nowrap text-sm text-center">{formatFrequency(job.frequency)}</td>
                                    <td className="px-4 py-4 whitespace-nowrap text-center">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${job.status === 'active' ? 'bg-green-100 text-green-800' :
                                                job.status === 'paused' ? 'bg-yellow-100 text-yellow-800' :
                                                    'bg-red-100 text-red-800'
                                            }`}>
                                            {job.status}
                                        </span>
                                    </td>
                                    <td className="px-4 py-4 whitespace-nowrap text-sm text-center">{job.keywords.join(', ')}</td>
                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-center">
                                        <button className="mr-2" onClick={() => handleStatusToggle(job.job_id, job.status)}>
                                            {job.status === 'active' && <CiPause1 size={16} />}
                                            {job.status === 'pause' && <CiPlay1 size={16} />}
                                        </button>
                                        <button className="text-red-600 hover:text-red-900" onClick={() => {
                                            setShowDeleteJobModal({ show: true, job_id: job.job_id });
                                        }}>
                                            <Trash2 size={16} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
import React, { createContext, useState } from 'react';
export const Context = createContext();

export const Provider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [orgData, setOrgData] = useState(null);
    const [channelData, setChannelData] = useState(null);
    const [open, setOpen] = useState(true);
    const [openInsight, setOpenInsight] = useState(true);
    const [knowledgespace, setKnowledgespace] = useState([]);
    const [showSpaceModal, setShowSpaceModal] = useState(false);
    const [showSmartSpaceModal, setShowSmartSpaceModal] = useState(false);
    const [showChannelModal, setShowChannelModal] = useState({ show: false, id: null });
    const [showIssueModal, setShowIssueModal] = useState(false);
    const [dashboardData, setDashboardData] = useState(null);
    const [onboardingData, setOnboardingData] = useState(null);
    const [showDashboard, setShowDashboard] = useState(false);
    const [showSchedulerModal, setShowSchedulerModal] = useState(false);
    const [showShareInsightModal, setShowShareInsightModal] = useState(false);
    const [showDeleteSpaceModal, setShowDeleteSpaceModal] = useState({ show: false, id: null }); // State for delete space modal
    const [showDeleteChannelModal, setShowDeleteChannelModal] = useState({ show: false, channel_id: null, workspace_id:null}); 
    const [showDeleteInsightModal, setShowDeleteInsightModal] = useState({ show: false, channel_id: null, workspace_id:null, insight_id:null}); 
    const [darkTheme, setDarkTheme] = useState(localStorage.getItem('theme') === 'false'? false : true);
    const [pathIndex, setPathIndex] = useState({ org_id: null, workspace_id: null, channel_id: null, insight_id: null });
    const [list, setList] = useState([]);
    const [insightDetails, setInsightDetails] = useState(null);
    const [summaryInfo, setSummaryInfo] = useState(null);
    const [channelDropdownIndex, setChannelDropdownIndex] = useState({ workspace: null, channel: null });
    const [loadingInsightsList, setLoadingInsightsList] = useState(false);
    const [loadingInsight, setLoadingInsight] = useState(false);
    const [answerLoading, setAnswerLoading] = useState(false);
    const [activeSection, setActiveSection] = useState(0);
    const [selectedSectionDesktop, setSelectedSectionDesktop] = useState('Home');
    const [showChat, setShowChat] = useState(false);
    const [allThreadData, setAllThreadData] = useState(null);
    const [messageInInsight, setMessageInInsight] = useState(null);
    const [messages, setMessages] = useState([]);
    const [isPromptLoading, setIsPromptLoading] = useState(null);
    const [actionState, setActionState] = useState(null); // Added DummyState
    const [notesId, setNotesId] = useState(null);



    const toggleTheme = () => {
        // Retrieve the theme value from localStorage and convert it to boolean
        const currentTheme = localStorage.getItem('theme') === 'true';
    
        // Toggle the theme based on the current value
        const newTheme = !currentTheme;
    
        // Store the new theme value as a string
        localStorage.setItem('theme', newTheme.toString());
    
        // Update the state
        setDarkTheme(newTheme);
    
        // Toggle the dark class on the document
        if (newTheme) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    };
    
    return (
        <Context.Provider value={{
            knowledgespace,
            setKnowledgespace,
            orgData, 
            setOrgData,
            pathIndex,
            setPathIndex,
            channelData,
            setChannelData,
            summaryInfo,
            setSummaryInfo,
            showSpaceModal,
            setShowSpaceModal,
            showChannelModal,
            setShowChannelModal,
            showIssueModal,
            setShowIssueModal,
            showSchedulerModal,
            setShowSchedulerModal,
            showDeleteSpaceModal, 
            setShowDeleteSpaceModal, 
            showDeleteChannelModal,
            setShowDeleteChannelModal,
            showDeleteInsightModal,
            setShowDeleteInsightModal,
            showShareInsightModal,
            setShowShareInsightModal,
            showSmartSpaceModal, 
            setShowSmartSpaceModal,
            channelDropdownIndex,
            setChannelDropdownIndex,
            dashboardData, setDashboardData,
            showDashboard, setShowDashboard,
            onboardingData, setOnboardingData,
            activeSection,
            setActiveSection,
            darkTheme,
            setDarkTheme,
            toggleTheme,
            list,
            setList,
            open,
            setOpen,
            openInsight,
            setOpenInsight,
            userData,
            selectedSectionDesktop,
             setSelectedSectionDesktop,
            setUserData,
            insightDetails,
            setInsightDetails,
            loadingInsightsList, 
            setLoadingInsightsList,
            loadingInsight, 
            setLoadingInsight,
            showChat, setShowChat,
            allThreadData, setAllThreadData,
            messageInInsight, setMessageInInsight,
            messages, setMessages,
            answerLoading, setAnswerLoading,
            isPromptLoading, setIsPromptLoading,
            actionState, setActionState,
            notesId, setNotesId
        }}>
            {children}
        </Context.Provider>
    );
};

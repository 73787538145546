import React, { useContext, useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion";
import { Context } from '../../Context/Context';
import { HiArrowRight } from 'react-icons/hi';
import { Dialog, Transition } from '@headlessui/react';
import { createKnowledgeSpace } from '../../services/workspaceService';
import { createSpacePrompt } from '../../services/promptServices';
import { BsToggle2Off, BsToggle2On } from 'react-icons/bs';

const AddSpaceModal = () => {
    const { showSpaceModal, setShowSpaceModal, setPathIndex, pathIndex, setIsPromptLoading, knowledgespace } = useContext(Context);
    const [workspaceName, setWorkspaceName] = useState('');
    const { darkTheme } = useContext(Context);
    const { setKnowledgespace } = useContext(Context);
    const [inputEmoji, setInputEmoji] = useState("default");
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);

    const handleClose = () => {
        setShowSpaceModal(false);
        setInputEmoji("default");
        setWorkspaceName("");
    };

    const handleDone = async () => {
        try {
            setLoading(true);
            handleClose();
            if (workspaceName.trim() === '') return;
            const createdKnowledgeSpace = await createKnowledgeSpace(workspaceName, pathIndex.org_id, inputEmoji);
            if (createdKnowledgeSpace) {
                const newKnowledgeSpace = {
                    id: createdKnowledgeSpace.data.space_id,
                    name: createdKnowledgeSpace.data.name,
                    icon: inputEmoji,
                    path: ["Knowledge Space >>", createdKnowledgeSpace.data.name],
                    channels: [],
                };
                setKnowledgespace((prevData) => [...prevData, newKnowledgeSpace]);
                setPathIndex((prevPathIndex) => ({
                    ...prevPathIndex,
                    workspace_id: createdKnowledgeSpace.data.space_id,
                    channel_id: null,
                    insight_id: null,
                }));
            }
            setWorkspaceName('');
        } catch (error) {
            console.log("Error:", error);
        } finally {
            setLoading(false);
            handleClose();
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleDone();
        }
    };

    return (
        <Dialog open={showSpaceModal} onClose={handleClose} className="relative z-10 focus:outline-none" initialFocus={inputRef}>
            <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className={`fixed inset-0 ${darkTheme ? "bg-black" : "bg-white"} bg-opacity-65 backdrop-blur-md`} aria-hidden="true" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 mb-48 w-screen overflow-visible rounded-lg">
                <div className="flex min-h-full items-center justify-center p-4 text-center rounded-lg">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >

                        <Dialog.Panel
                            className={`relative transform overflow-visible rounded-xl transition-all sm:my-8 sm:max-w-3xl w-full ${darkTheme
                                ? 'bg-none text-white'
                                : 'bg-none text-black'
                                }`}
                        >
                            {/* <p className={`text-[30px] py-4 ${darkTheme ? "text-gray-300" : ""}`}>What do you want to research about today?</p> */}
                            <div className={`items-center rounded-xl w-[100%] border ${darkTheme ? "bg-[#000] border-[#505050]" : "bg-white"}`}>
                                {/*<div className={`flex items-center justify-between mb-1 border-b w-[100%] ${darkTheme ? "border-[#ffffff16]" : "bg-[#fafbfa]"}`}>
                                    <div className={`flex items-center gap-2 ${darkTheme ? "text-[#909090] border-[#ffffff16]" : "text-zinc-800"}`}>
                                        <h2 className="text-lg font-medium p-2">{isOldModal ? 'Tell us about the space you want to create' : 'Tell us about the space you want to create'}</h2>
                                        {!isOldModal && (
                                            <motion.div
                                                animate={{ x: [0, 4, 0] }}
                                                transition={{
                                                    duration: 1.5,
                                                    repeat: Infinity,
                                                    ease: "easeInOut"
                                                }}
                                            >
                                                <HiArrowRight className="h-5 w-5 text-[#f15025]" />
                                            </motion.div>
                                        )}
                                    </div> 
                                    <button
                                        type="button"
                                        onClick={handleClose}
                                        className="text-[#545657] hover:text-gray-500 pr-4 pt-1"
                                    >
                                        <RiCloseFill className="h-7 w-7" />
                                    </button>
                                </div>*/}

                                <div className="w-full rounded-xl p-6">
                                    <div className="flex flex-col gap-3">
                                        <textarea
                                            ref={inputRef}
                                            placeholder="Workspace Name"
                                            className={`w-full px-3 bg-transparent border-none outline-none resize-none text-base ${darkTheme ? "text-zinc-200 placeholder-zinc-500" : "text-zinc-800 placeholder-zinc-400"}`}
                                            value={workspaceName}
                                            onChange={(e) => setWorkspaceName(e.target.value)}
                                            maxLength={15}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                </div>

                                <div className={`px-6 py-1 flex justify-end sm:px-6 border-t ${darkTheme ? "border-[#404040]" : ""}`}>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-2"></div>
                                        <button
                                            onClick={handleDone}
                                            className={`sm:p-2 3xll:p-4 rounded-full ${ (workspaceName?.length > 0) ? "text-[#f15025] hover:text-[#f15025]" : "text-[#404040]"}`}
                                        >
                                            <motion.div
                                            >
                                                <HiArrowRight className="h-5 w-5" /> {/* Arrow pointing right */}
                                            </motion.div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    );
};

export default AddSpaceModal;

import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { AiOutlineCalendar, AiOutlineBell, AiOutlineSetting } from 'react-icons/ai';
import { IoChatbubblesOutline } from 'react-icons/io5';
import { Context } from '../../Context/Context';
import { createChannelInsightThread, getThreadByChannelInsight } from '../../services/chatService';
import { FaTrashAlt } from 'react-icons/fa';
import { MdDeleteOutline } from "react-icons/md";
import { TbNotes } from "react-icons/tb";

import { GoDotFill } from "react-icons/go";
import { createNote } from '../../services/notesService';

const RightSidebar = ({ darkTheme }) => {
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const { setShowChat, allThreadData, setAllThreadData, pathIndex, setShowDeleteInsightModal, actionState, setActionState, insightDetails, setInsightDetails, notesId, setNotesId } = useContext(Context);
  const handleChatClick = async () => {
    setActionState(1);
    try {
      setShowChat(true);
      if (allThreadData?.length === 0) {
        const response = await createChannelInsightThread(pathIndex.channel_id, pathIndex.insight_id);

        if (response?.data && pathIndex.channel_id && pathIndex.insight_id) {
          const thread_data = await getThreadByChannelInsight(pathIndex.channel_id, pathIndex.insight_id);

          if (thread_data?.data) {
            setAllThreadData(thread_data.data);
          }
        }
      }
    } catch (error) {
      console.error("Error in handleChatClick:", error);
    }
  };
  const handleDeleteInsight = async () => {
    try {
      setShowDeleteInsightModal({ show: true, channel_id: pathIndex.channel_id, workspace_id: pathIndex.workspace_id, insight_id: pathIndex.insight_id });
    } catch (error) {
      console.error("Error deleting insight:", error);
    }
  };
  const handleClickNotes = async () => {
    try {
        setActionState(0);
        if (insightDetails?.note_id === null) {
            const response = await createNote(pathIndex.channel_id, pathIndex.insight_id);
            if (response && response?.data) {
                console.log(response?.data?.note_id)
                if(response?.data?.note_id) setNotesId(response?.data?.note_id);
                setInsightDetails({ ...insightDetails, note_id: response?.data?.note_id });
            }
        }
        else{
          if(insightDetails?.note_id) setNotesId(insightDetails?.note_id);
        }
    } catch (error) {
        console.error("Error updating insight details:", error);
    }
};


  const icons = [
    { icon: TbNotes, label: 'Notes', func: handleClickNotes, id: 0 },
    { icon: IoChatbubblesOutline, label: 'Chat with insight', func: handleChatClick, id: 1 },
    { icon: MdDeleteOutline, label: 'Delete Insight', func: handleDeleteInsight },
    // { icon: AiOutlineSetting, label: 'Settings', func: handleSettingClick },
  ];

  return (
    <div className={`fixed sm:right-0 3xl:right-0 top-[40px] transform  z-[11] sm:w-[50px] 3xl:w-[70px] h-screen bg-none border ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa] border-[#3d3d3d2d]"}`}>
      <div className=' flex justify-center items-center flex-col py-10'>
        {icons.map(({ icon: Icon, label, func, id }, index) => (
          <motion.div
            key={index}
            className="relative sm:p-3 3xl:px-1 3xl:py-4 cursor-pointer"
            onHoverStart={() => setHoveredIcon(index)}
            onHoverEnd={() => setHoveredIcon(null)}
            onClick={func}
          >
            <div className='flex flex-row justify-between items-center'>
              <Icon className={`sm:w-5 sm:h-5 3xl:w-6 3xl:h-6 ${darkTheme ? 'text-[#404040]' : 'text-gray-600'} ${actionState === id ? "!text-[#f15025]" : ""}`} />
              {/* <GoDotFill className={`text-[#f15025] text-[10px] ${(actionState === null || actionState !== id) ? "hidden" : "block" }`} /> */}
            </div>
            {hoveredIcon === index && (
              <motion.div
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                className={`absolute right-full top-1/2 transform -translate-y-1/2 mr-2 px-2 py-1 text-[12px] text-white rounded whitespace-nowrap ${darkTheme ? "bg-[#808080]" : "bg-[#808080]"}`}
              >
                {label}
              </motion.div>
            )}
          </motion.div>
        ))}

      </div>
    </div>
  );
};

export default RightSidebar;

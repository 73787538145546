import React, { useContext, useEffect, useState, useRef } from "react";
import { IoSearch } from "react-icons/io5";
import { MdRunCircle } from "react-icons/md";
import { Context } from '../../../Context/Context';
import { getInsightDetails, updateInsightDetails } from '../../../services/insightService';
import 'react-loading-skeleton/dist/skeleton.css';
import InsightContainer from "./InsightContainer";
import { FaCirclePlus } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { getThreadByChannelInsight } from "../../../services/chatService";
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import { Button } from "antd";


const InsightList = ({ spaceId, channelId, insightId }) => {
    const { setSummaryInfo, setShowIssueModal, setInsightDetails, setAllThreadData, setShowSchedulerModal, pathIndex, list, setList, darkTheme, setPathIndex, loadingInsightsList, setLoadingInsight, openInsight, setActiveSection, knowledgespace, setKnowledgespace, setShowChat, setMessages } = useContext(Context);
    const [selectedInsightId, setSelectedInsightId] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredInsights, setFilteredInsights] = useState(list);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [read, setRead] = useState(false);
    const inputRef = useRef(null);
    const timeoutRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchThreadData = async (currentInsightId) => {
            setShowChat(false);
            try {
                if (pathIndex?.channel_id && currentInsightId) {
                    const thread_data = await getThreadByChannelInsight(pathIndex?.channel_id, currentInsightId);
                    if (thread_data?.data.length > 0) {
                        setAllThreadData(thread_data?.data);
                        const initialMessages = thread_data.data[0].conversations.flatMap(conv => [
                            { text: conv.question, sender: 'user' },
                            { text: conv.answer, sender: 'bot' }
                        ]);
                        setMessages(initialMessages);
                    }
                }
                else {
                    setMessages([]);
                }
            } catch (error) {
            console.error("Error fetching thread data:", error);
        }
    };

    if (pathIndex?.insight_id) {
        setSelectedInsightId(pathIndex?.insight_id);
        fetchThreadData(pathIndex?.insight_id);
    } else if (!loadingInsightsList && list && list.length > 0 && !read) {
        const firstInsight = list[0];
        setSelectedInsightId(firstInsight.id);
        // if(pathIndex.insight_id !== firstInsight.id){
        //     setPathIndex(prevPathIndex => ({
        //         ...prevPathIndex,
        //         insight_id: firstInsight.id,
        //     }));
        // }
        fetchThreadData(firstInsight.id);
        setRead(false);
    }
}, [list]);

useEffect(() => {
    let filtered = list;

    if (searchQuery) {
        filtered = filtered.filter(insight =>
            (insight.title && insight.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (insight.description && insight.description.toLowerCase().includes(searchQuery.toLowerCase()))
        );
    }

    setFilteredInsights(filtered);
}, [searchQuery, list]);

const handleClick = () => {
    if (pathIndex && spaceId !== null && channelId !== null) {
        setShowIssueModal(true);
    }
};

const handleIssueClick = async (insight) => {
    try {
        if (pathIndex && channelId && insight && insight.id) {
            setLoadingInsight(true);
            setShowChat(false);
            setMessages([]);
            const response = await getInsightDetails(channelId, insight.id);
            setPathIndex(prevPathIndex => ({
                ...prevPathIndex,
                insight_id: insight.id
            }));

            if (response && response.data) {
                setInsightDetails(response.data);
                setSummaryInfo(response.data);
                setSelectedInsightId(insight.id);
                navigate(`/home/sp/${spaceId}/ch/${channelId}/in/${insight.id}`, { replace: true });
                if (!response.data.read) {
                    const updateInsight = await updateInsightDetails(insight.id, {
                        read: true,
                    });

                    if (updateInsight) {
                        let insightToUpdate = list.find(i => i.id === insight.id);

                        if (insightToUpdate) {
                            insightToUpdate.read = true;
                            setRead(true);
                            const updatedList = list.map(i =>
                                i.id === insight.id ? { ...i, read: true } : i
                            );

                            setList(updatedList); // Triggers the list change
                            const updatedKnowledgeSpaces = knowledgespace.map(space => {
                                return {
                                    ...space,
                                    channels: space.channels.map(channel => {
                                        if (channel.id === channelId && channel.unread_insights_count > 0) {
                                            return {
                                                ...channel,
                                                unread_insights_count: channel.unread_insights_count - 1,
                                            };
                                        }
                                        return channel;
                                    }),
                                };
                            });

                            setKnowledgespace(updatedKnowledgeSpaces); // Update the knowledge space with the reduced unread count

                        }
                    } else {
                        console.error("Failed to update insight read status");
                    }
                }
                setLoadingInsight(false);
                let thread_data;
                if (channelId && insight.id) {
                    thread_data = await getThreadByChannelInsight(channelId, insight.id);
                }
                if (thread_data?.data) {
                    setAllThreadData(thread_data.data)
                    const conversation_array = thread_data.data.map((item) => ({
                        thread_id: item.thread_id,
                        insight_id: item.insight_id,
                        conversations: item.conversations,
                    }));

                    if (conversation_array && insight.id) {
                        const selectedThread = conversation_array.find(item => item.insight_id === insight.id);
                        if (selectedThread && selectedThread?.conversations?.length) {
                            const initialMessages = selectedThread.conversations.flatMap(conv => [
                                { text: conv.question, sender: 'user' },
                                { text: conv.answer, sender: 'bot' }
                            ]);
                            setMessages(initialMessages);
                        } else {
                            setMessages([]);
                        }
                    }
                }
            }
        }
    } catch (error) {
        console.error("Error fetching insight details:", error);
        setLoadingInsight(false);
    }
};

const renderInsightCard = (item, index, arr) => (
    <div key={item.id} onClick={async () => { await handleIssueClick(item); setActiveSection(3); }} className="w-full cursor-pointer">
        <div className={`flex overflow-hidden border ${darkTheme ? "hover:bg-[#2d2d2e] border-[#ffffff16]" : "hover:bg-[#e8e8e9]"} ${selectedInsightId === item.id ? (darkTheme ? "bg-[#2d2d2e]" : "bg-[#e8e8e9]") : ""} ${openInsight ? "rounded-md p-3 sm:p-3 lg:p-4" : "justify-center p-2 bg-transparent"}`}>
            {item.read === false && <div className="text-blue-500 flex items-center ml-[-10px]"><GoDotFill /></div>}
            {item.image && (
                <img
                    src={item.image}
                    alt="Example"
                    className={`w-20 h-20 sm:w-8 sm:h-8 lg:w-12 lg:h-12 ${item.read === true ? "ml-[15px]" : "ml-[9px]"} ${openInsight ? "sm:mr-2 lg:mr-4" : ""} object-cover`}
                    onError={(e) => {
                        e.currentTarget.onerror = null; // Prevents looping in case the fallback fails
                        e.currentTarget.src = 'https://static.vecteezy.com/system/resources/previews/000/571/059/original/vector-newspaper-icon.jpg'
                    }}
                />
            )}
            {item.logo && (
                <img
                    src={item.logo}
                    alt="Example"
                    className={`w-6 h-6 mt-16 sm:w-3 sm:h-3 sm:mt-8 lg:w-4 lg:h-4 lg:mt-9  ${openInsight ? "ml-[-18px] md:ml-[-20px] lg:ml-[-25px] md:mr-1 lg:mr-3" : "ml-[-12px]"} `}
                    onError={(e) => {
                        e.currentTarget.onerror = null; // Prevents looping in case the fallback fails
                        e.currentTarget.src = '/logo.png'; // Replace with the path to your default image
                    }}
                />
            )}
            {openInsight && (
                <div className="flex flex-col flex-grow overflow-hidden px-2 sm:px-0">
                    <p className="text-[16px] sm:text-[12px] lg:text-[16px] sm:truncate sm:w-full">
                        <span className="sm:hidden">{highlightText(item.title)}</span>
                        <span className="hidden sm:inline" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{highlightText(item.title)}</span>
                    </p>
                    <p className="hidden text-[9px] sm:text-[10px] sm:block lg:text-[14px] text-gray-400 sm:truncate sm:w-full">
                        <span className="sm:hidden">{highlightText(item.description) || 'No description Available'}</span>
                        <span className="hidden sm:inline" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{highlightText(item.description) || 'No description Available'}</span>
                    </p>
                </div>
            )}
        </div>
        {openInsight && (
            <div className="flex justify-center">
                <div className={`${index === arr.length - 1 ? "w-0" : "w-[90%]"} ${darkTheme ? "border-[1px] border-[#2d2d2e]" : ""}`}></div>
            </div>
        )}
    </div>
);

const handleSearchBlur = () => {
    timeoutRef.current = setTimeout(() => {
        searchQuery === "" ? setIsSearchActive(!isSearchActive) : setIsSearchActive(isSearchActive);
    }, 200);
};

const handleSearchFocus = () => {
    if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
    }
};
const highlightText = (text) => {
    if (!searchQuery) return text; // Return original text if no query
    if (text === null) return "";
    const regex = new RegExp(`(${searchQuery})`, 'gi'); // Create a regex to match the query
    const parts = text !== null ? text.split(regex) : []; // Split the text by matched query

    return parts.map((part, index) =>
        part.toLowerCase() === searchQuery.toLowerCase() ? (
            <span key={index} className="bg-yellow-400">{part}</span> // Highlight matched part
        ) : (
            part
        )
    );
};


return (
    <div className={`sm:min-h-screen lg:w-[350px] xl:w-[400px] 2xl:w-[450px] rounded-tl-lg border transition-all duration-200 sm:mb-0 ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa]"}`}>
        <div className={`flex justify-between items-center px-4 sm:p-0 border-b ${darkTheme ? "border-[#ffffff16]" : "border-[#3d3d3d2d]"}`}>
            {<div className={`${darkTheme ? "text-white" : "text-[#000000] font-semibold"} p-2 sm:mx-2`}>Inbox</div>}
            <div className={`flex p-[1px] items-center gap-2 ${openInsight ? "" : "justify-center"}`}>
                {openInsight && <div className="relative m-1 mr-4 pt-[1px] flex items-center">
                    {!isSearchActive && (
                        <IoSearch
                            onClick={() => setIsSearchActive(true)}
                            className={`cursor-pointer my-2 transition-all duration-200 ease-in-out ${darkTheme ? "text-white" : "text-black"}`}
                        />
                    )}
                    {isSearchActive && (
                        <input
                            ref={inputRef}
                            type="text"
                            placeholder="Search insights"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className={`pl-8 pr-4 py-1 rounded-md outline-none transition-all duration-200 ease-in-out ${isSearchActive ? "w-[130px] sm:w-[150px] lg:w-[200px]" : "w-0"} ${darkTheme ? "bg-[#2d2d2e]  text-white" : "bg-[#e8e8e9] text-black"}`}
                            onBlur={handleSearchBlur}
                            onFocus={handleSearchFocus}
                            autoFocus
                        />
                    )}
                    {isSearchActive && (
                        <IoSearch className={`absolute left-2 top-1/2 transform -translate-y-1/2 ${darkTheme ? "text-white" : "text-black"}`} />
                    )}
                </div>}
            </div>
        </div>
        <div className="h-screen">
            <div className={`py-1 px-4 ${darkTheme ? "border-[#ffffff16]" : "border-[#3d3d3d2d]"} `}>
                <button
                    className={`w-full flex flex-row justify-center py-1 px-2 ${darkTheme ? "bg-[#353535] border-[#ffffff16] text-gray-200" : "bg-white border-[#3d3d3d2d] text-[#808080]"} border rounded-lg hover:bg-[#f15025] hover:text-white`}
                    onClick={handleClick}
                >
                    <p className="px-2 text-[15px"> + </p> Add Insights
                </button>
            </div>
            <InsightContainer
                darkTheme={darkTheme}
                loadingInsightsList={loadingInsightsList}
                filteredInsights={filteredInsights}
                renderInsightCard={renderInsightCard}
                searchQuery={searchQuery}
            />
        </div>
    </div>
);
};

export default InsightList;
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const features = [
  {
    title: "Memory for LLMs",
    description:
      "Enhance your language models with advanced memory management techniques, improving context retention and response relevance.",
  },
  {
    title: "Agent Development Environment",
    description:
      "Build and refine AI agents in a controlled, customizable environment that accelerates development and testing.",
  },
  {
    title: "Prototype to Production",
    description:
      "Seamlessly transition your AI projects from experimental prototypes to robust, scalable production systems.",
  },
];

const FeatureSection = ({ darkTheme }) => {
  const [activeFeatureIndex, setActiveFeatureIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveFeatureIndex((prevIndex) => (prevIndex + 1) % features.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div
      className={`border p-8 my-16 ${true ? "bg-[#181818] text-[#ADB5BD] border-gray-500" : ""}`}
    >
      <div
        className={`grid grid-cols-1 md:grid-cols-3 gap-8 border p-6 ${true ? "bg-[#181818] text-[#ADB5BD] border-gray-500" : ""}`}
      >
        <div className="col-span-1 p-4 ">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`h-32 relative overflow-hidden p-4 border ${true ? "bg-[#181818] text-[#ADB5BD] border-gray-500" : ""}`}
            >
              <motion.div
                className={`absolute inset-0 bg-[#f15025] ${
                  index !== activeFeatureIndex && "hidden"
                }`}
                initial={{ width: "0%" }} // Start at 0%
                animate={
                  index === activeFeatureIndex
                    ? {
                        width: "100%",
                        transition: { duration: 5, ease: "linear" },
                      }
                    : { width: "0%" }
                }
              />

              <div className={`absolute inset-0 flex items-center p-4 ${darkTheme ? "bg-[#181818] text-[#ADB5BD]" : ""}`}>
                <h3
                  className={`text-xl font-semibold z-10 `}
                >
                  {feature.title}
                </h3>
              </div>
            </div>
          ))}
        </div>
        <div className="col-span-2">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeFeatureIndex}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              <h2
                className={`text-[38px] font-bold mb-4 p-4 ${darkTheme ? "bg-[#202020] text-[#ADB5BD]" : ""}`}
              >
                {features[activeFeatureIndex].title}
              </h2>
              <p className={`mb-6`}>
                {features[activeFeatureIndex].description}
              </p>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;

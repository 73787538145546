import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from 'antd';
import { Context } from '../../Context/Context';
import { createCategories, getNewCategories } from '../../services/schedulerJobService';
import { useNavigate } from 'react-router-dom';

const OnboardingPage = () => {
    const [currentSection, setCurrentSection] = useState(0);
    const { darkTheme } = useContext(Context);
    const [onboardingData, setOnboardingData] = useState([]);
    const [selections, setSelections] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // Fetch sections on component mount
    useEffect(() => {
        fetchSections();
    }, []);

    const fetchSections = async () => {
        try {
            const response = await getNewCategories();
            if (response?.data) {
                const interestsArray = Object.keys(response.data.interests).map(title => ({
                    title,
                    options: response.data.interests[title]
                }));
                setOnboardingData(interestsArray);
            }
        } catch (error) {
            console.error("Error getting categories:", error);
            if (error.response) {
                console.error("API error response:", error.response);
            }
        }
    };

    const handleSelection = (option) => {
        const newSelections = [...selections];
        newSelections[currentSection] = option;
        setSelections(newSelections);
        // Move to the next section if available
        if (currentSection < onboardingData.length - 1) {
            setCurrentSection(currentSection + 1);
        }
    };

    const handleCreate = async () => {
        const interestsData = onboardingData.reduce((acc, section, index) => {
            if (selections[index]) {
                acc[section.title] = [selections[index]];
            }
            return acc;
        }, {});
    
        const data = {
            interests: interestsData
        };
        try {
            const response = await createCategories(data);
            if(response?.data){
                navigate('/home');
            }
        } catch (error) {
            console.log("Error creating categories:", error);
        }
    };
    
    const atLeastTwoSelected = selections.filter(selection => selection !== '').length >= 2;

    return (
        <div className={`h-screen w-screen flex flex-col items-center justify-center ${darkTheme ? "bg-[#202020]" : "bg-gradient-to-r from-gray-100 via-gray-200 to-gray-300"}`} >
            <div className={`sm:max-w-7xl 3xl:max-w-[70%] w-full px-8 py-6 ${darkTheme ? "bg-[#181818] shadow-[0_4px_10px_rgba(150,150,150,0.15)]" : "bg-white"} rounded-lg shadow-lg h-auto `}>
                <div className="flex mb-4">
                    {onboardingData.map((section, index) => (
                        <motion.div 
                            className={`w-full h-4 cursor-pointer`} 
                            onClick={() => index < currentSection && setCurrentSection(index)} 
                            key={index}
                        >
                            <motion.div
                                className={`flex-1 h-2 mx-1 rounded-full cursor-pointer ${
                                    index < currentSection 
                                        ? 'bg-[#f15025]' 
                                        : index === currentSection 
                                            ? 'bg-[#f15025]' 
                                            : 'bg-gray-400'
                                }`}
                                transition={{ duration: 0.5 }}
                            />
                        </motion.div>
                    ))}
                </div>

                {onboardingData.length > 0 ? (
                    <motion.div
                        key={currentSection}
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -50 }}
                        transition={{ duration: 0.5 }}
                    >
                        <h2 className={`text-2xl font-bold mb-4 ${darkTheme ? "text-gray-400" : "text-gray-800"}`}>
                            {onboardingData[currentSection]?.title}
                        </h2>
                        <div className="grid grid-cols-3 sm:grid-cols-4 gap-3 mb-6 py-4 px-6">
                            {onboardingData[currentSection]?.options.map((option) => (
                                <button
                                    key={option}
                                    className={`py-3 px-4 border rounded-full text-sm font-medium transition-all ${
                                        selections[currentSection] === option
                                            ? 'bg-[#f15025] text-white'
                                            : `${darkTheme ? "text-gray-300 border-gray-700" : "text-gray-700"}`
                                    } hover:bg-[#f15025] hover:text-white`}
                                    onClick={() => handleSelection(option)}
                                >
                                    {option}
                                </button>
                            ))}
                        </div>
                        {/* Show the Create button only if all sections are completed */}
                            <div className="flex justify-end mt-4">
                                <button
                                    onClick={handleCreate}
                                    disabled={!atLeastTwoSelected}
                                    className={`flex items-center justify-center p-2 w-[180px] rounded-md border border-green-600 bg-green-500 text-white transition-all ${
                                        !atLeastTwoSelected
                                            ? 'opacity-50 cursor-not-allowed'
                                            : 'hover:bg-green-600'
                                    }`}
                                >
                                    Create My Space
                                </button>
                            </div>
                    </motion.div>
                ) : (
                    <div className="text-center text-gray-500">Loading...</div>
                )}
            </div>
        </div>
    );
};

export default OnboardingPage;

import React, { useState, useEffect, useRef, useContext } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Context } from "../../Context/Context";

const PdfViewerPage = () => {
    const { darkTheme } = useContext(Context);
    const [file, setFile] = useState(null);
    const viewerContainerRef = useRef(null);

    // Plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const onFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(URL.createObjectURL(selectedFile));
        }
    };

    return (
        <div
            className={`min-h-screen w-full mx-auto border-x ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa]"}`}
            ref={viewerContainerRef}
        >
            <div className={` border ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa]"}`}>
                <div className={`flex justify-between items-center border-b p-[1px] ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"}`}>
                    <div className="p-2 mx-2 min-h-[40px] flex-grow">
                        Pdf Viewer
                    </div>
                </div>
            </div>

            {/* File Upload Input */}
            <input
                type="file"
                accept="application/pdf"
                onChange={onFileChange}
                className="mb-4 px-4 py-2 rounded cursor-pointer"
            />

            {/* PDF Viewer */}
            {file ? (
                <div
                    className="h-[75vh] w-full border rounded-lg shadow-lg overflow-hidden"
                    style={{
                        background: "#f5f5f5",
                    }}
                >
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                        <Viewer
                            fileUrl={file}
                            theme="light"
                            defaultScale="PageFit"
                            withCredentials={false}
                        />
                    </Worker>
                </div>
            ) : (
                <div className="text-gray-500 p-2">Please upload a PDF to preview</div>
            )}
        </div>
    );
};

export default PdfViewerPage;

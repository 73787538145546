import React, { useContext, Suspense } from "react";
import { Context } from "../../../Context/Context";
import { format } from "date-fns";
const SkeletonInsights = React.lazy(() => import("../../../components/SkeletonInsights/SkeletonInsights"));

const Summary = () => {
    const { insightDetails, darkTheme, loadingInsight, actionState } = useContext(Context);

    if (loadingInsight) {
        return (
            <Suspense fallback={<div></div>}>
                <SkeletonInsights />
            </Suspense>
        );
    }

    if (!insightDetails) {
        return (
            <>
                <center className={`flex items-center justify-center text-center sm:w-[95%] w-full`}>
                    <center className={`text-center p-8 rounded-lg ${darkTheme ? "text-gray-100" : "text-gray-800"}`}>
                        <h2 className={`text-xl font-semibold mb-4`}>
                            No Insight Details Available
                        </h2>
                        <p className="text-md font-light">
                            We're sorry, but it looks like there's nothing to show right now.
                        </p>
                    </center>
                </center>
            </>
        );
    }

    const { source_data, summary, url, created_at } = insightDetails;

    return (
        <div className={`flex flex-col md:mx-[5px] lg:mx-5 my-2 w-[100%] text-[#ffffffea] h-screen  ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa]"}`}>
            <div className="flex-grow">
                <div className="flex flex-row mt-3 w-[95%]">
                    <img
                        src={source_data.top_image}
                        alt="Top"
                        loading="lazy" // Lazy loading the image
                        className="w-[140px] h-[120px] p-1 mt-4 sm:mt-0 sm:w-32 sm:h-28 sm:p-1 lg:w-56 lg:p-3 lg:h-40 rounded-md shadow-md"
                        onError={(e) => {
                            e.currentTarget.onerror = null; // Prevents looping in case the fallback fails
                            e.currentTarget.src = '/logo.png'; // Replace with the path to your default image
                        }}
                    />
                    <div className="mt-1 py-1 px-4">
                        <div className="flex items-center space-x-2 sm:p-1 sm:py-0 py-1">
                            <a href={url} target="_blank" rel="noopener noreferrer" className={`text-[20px] ${darkTheme ? "text-[#ffffffa3]" : "text-[#1a1a1aa3]"}`}>
                                <h2 className={`text-[17px] sm:text-[18px] lg:text-[24px] font-bold ${darkTheme ? "text-white" : "text-black"} hover:text-blue-500 hover:underline transition-colors`}>
                                    {source_data.title}
                                </h2>
                            </a>
                        </div>
                        <p className={`mt-1 inline-flex sm:mt-2 sm:p-1 text-[12px] ${darkTheme ? "text-[#ffffffa3]" : "text-[#1a1a1aa3]"}`}>
                            <img
                                src={source_data.logo}
                                alt="Example"
                                loading="lazy" // Lazy loading the logo
                                className="w-4 h-4 mr-2"
                                onError={(e) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = '/logo.png';
                                }}
                            />
                            {source_data.published_date ? format(new Date(source_data.published_date), "MMMM dd, yyyy") : format(new Date(created_at), "MMMM dd, yyyy")}
                        </p>
                    </div>
                </div>

                <div className={`py-3 border-b w-full md:px-[1px] lg:px-1 ${darkTheme ? "text-[#ffffffa3] border-[#2a2929]" : "text-[#1a1a1aa3] border-opacity-20 font-semibold"}`}>
                    {summary.analysis.categories?.map((category, index) => (
                        <div key={index} className="mt-4">
                            {(summary.analysis.insights)
                                .find(insight => insight.category === category) && <h3 className={`text-[17px] md:text-[16px] lg:text-[20px] capitalize ${darkTheme ? "text-white" : "text-black"}`}>{category}</h3>}
                            {(summary.analysis.insights)
                                .find(insight => insight.category === category) && <ul className={`mt-2 list-disc rounded-md px-1 ${darkTheme ? "text-[#ffffffa3]" : "text-[#1a1a1aa3]"}`}>
                                    {(summary.analysis.insights)
                                        .find(insight => insight.category === category)
                                        .points.map((point, idx) => (
                                            <li key={idx} className={`mx-3 sm:mx-2 text-[16px] sm:p-[5px] lg:p-2 font-normal sm:text-[13px] lg:text-[16px] ${darkTheme ? "text-white" : "text-black"}`}>
                                                {point}
                                            </li>
                                        ))}
                                </ul>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Summary;

import React, { useState, useEffect } from 'react';

const HeroSection = ({ darkTheme, handleGoogleLogin }) => {
    const [typedText, setTypedText] = useState('');
    const [showCursor, setShowCursor] = useState(true);
    const fullText = 'Build Your Knowledge Engine.';

    useEffect(() => {
        let index = 0;
        let isTyping = true;

        const animateText = () => {
            if (isTyping && index <= fullText.length) {
                setTypedText(fullText.slice(0, index));
                index++;
            }

            if (index > fullText.length) {
                isTyping = false; // Stop typing after completion
            } else {
                setTimeout(animateText, 100); // Continue typing
            }
        };
        setTimeout(()=>{animateText()}, 200);
        // Blinking cursor effect
        const cursorInterval = setInterval(() => {
            setShowCursor((prev) => !prev);
        }, 500);

        return () => {
            clearTimeout(animateText);
            clearInterval(cursorInterval);
        };
    }, [fullText]);

    const renderTypedText = () => {
        const parts = typedText.split(' ');
        return (
            <>
                {parts.map((word, idx) =>
                    word === 'Your' ? (
                        <span key={idx} className="italic">{word} </span> // Make "Your" italic
                    ) : (
                        <span key={idx}>{word} </span>
                    )
                )}
            </>
        );
    };

    return (
        <div className="flex flex-col items-center justify-center text-left h-fit border-l border-r border-gray-500 mt-10 py-24">
            <h1 className="text-[36px] sm:text-[42px] lg:text-[64px] 3xl:text-[72px] font-extrabold mb-8 pt-4 leading-tight text-center whitespace-nowrap">
                <span className={`bg-clip-text roboto-mono-bold ${darkTheme ? "text-[#f15025]" : "text-black"}`}>
                    {renderTypedText()}
                    <span className={`${showCursor ? 'opacity-100' : 'opacity-0'} ml-[-10px]`}>|</span>
                </span>
            </h1>
            <p className={`text-left sm:text-lg md:text-xl mb-12 ${darkTheme ? "text-[#ADB5BD]" : "text-black"}`}>
                Get answers from your expert that learns and compounds knowledge, everyday.
            </p>
            <button
                type="primary"
                className={`py-3 px-12 text-md shadow-lg hover:bg-orange-600 ${
                    darkTheme ? "bg-[#f15025] text-white" : "bg-[#f15025] text-white"
                } hover:opacity-90 transition-all duration-300 border-0 rounded-full`}
                onClick={handleGoogleLogin}
            >
                Start Nowㅤ→
            </button>
            <div className="py-10 flex justify-center align-center">
                <img
                    src="/hero-img.webp"
                    className="h-[90%] w-[90%]"
                    alt="thumbnail"
                />
            </div>
        </div>
    );
};

export default HeroSection;

// import React, { useState, useEffect, useContext } from 'react';
// import { Context } from '../../Context/Context';

// const HeroSection = ({ handleGoogleLogin }) => {
//     const darkTheme = false;
//     // SVG for hand-drawn strikethrough line
//     const StrikethroughLine = () => (
//         <svg className="absolute left-0 top-[60%] w-full h-4 -translate-y-1/2 pointer-events-none">
//             <path
//                 d="M0,8 C5,5 15,11 30,8 C45,5 60,11 75,8 C90,5 100,11 115,8"
//                 stroke={darkTheme ? "#ffffff" : "#000000"}
//                 strokeWidth="2"
//                 fill="none"
//                 className="hand-drawn-line"
//             />
//         </svg>
//     );

//     return (
//         <div className={`w-full max-w-screen-xl mx-auto px-4 ${
//             darkTheme ? ' text-white' : ' text-black'
//         }`}>
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-1 items-center min-h-screen">
//                 {/* Left side with struck-through text */}
//                 <div className="space-y-6 text-2xl font-bold relative">
//                     <div className="relative w-fit">
//                         <img 
//                             src="/hero-image-text.png"
//                             className="h-[100%] w-[100%] text-white"
//                             alt="thumbnail"                        
//                         />
//                     </div>
//                     <div className={`relative w-fit border-2 ${
//                         darkTheme ? 'border-green-400 text-green-400' : 'border-green-600 text-green-600'
//                     } rounded-full px-6 py-2`}>
//                         Tensorweave ✓
//                     </div>
//                 </div>

//                 {/* Right side with search box */}
//                 <div className={`rounded-lg`}>
//                     <img
//                         src="/hero-img.webp"
//                         className="h-[100%] w-[100%]"
//                         alt="thumbnail"
//                     />
//                 </div>
//             </div>

//             {/* Bottom section */}
//             <div className="text-center mt-8">
//                 <p className={`text-xl mb-4 ${
//                     darkTheme ? 'text-gray-300' : 'text-gray-700'
//                 }`}>
//                     Fast and effective research with Tensorweave
//                 </p>
//                 <button
//                     onClick={handleGoogleLogin}
//                     className={`px-6 py-2 rounded-full border-2 ${
//                         darkTheme 
//                             ? 'border-white text-white' 
//                             : 'border-black text-black'
//                     } transition-colors`}
//                 >
//                     Try Now
//                 </button>
//             </div>

//             <style jsx>{`
//                 .hand-drawn-line {
//                     animation: draw 1s ease forwards;
//                     stroke-dasharray: 1000;
//                     stroke-dashoffset: 1000;
//                 }

//                 @keyframes draw {
//                     to {
//                         stroke-dashoffset: 0;
//                     }
//                 }
//             `}</style>
//         </div>
//     );
// };

// export default HeroSection;
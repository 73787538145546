import React, { useContext, useEffect, useState, useRef } from "react";
import { Context } from "../../../Context/Context";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createNote, updateNotes, getNotesDetails } from "../../../services/notesService";

const NoteSkeleton = () => (
  <div className="animate-pulse">
    <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
    <div className="space-y-3">
      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      <div className="h-4 bg-gray-200 rounded w-5/6"></div>
      <div className="h-4 bg-gray-200 rounded w-2/3"></div>
    </div>
  </div>
);

const Notes = () => {
  const { darkTheme, notesId, setNotesId } = useContext(Context);
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [lastSavedValue, setLastSavedValue] = useState("");

  const valueRef = useRef(value);
  const lastSavedValueRef = useRef(lastSavedValue);

  // Update refs whenever state changes
  useEffect(() => {
    valueRef.current = value;
    lastSavedValueRef.current = lastSavedValue;
  }, [value, lastSavedValue]);

  useEffect(() => {
    const loadNoteContent = async () => {
      if (!notesId) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const noteDetails = await getNotesDetails(notesId);
        const content = noteDetails?.data?.content || "";
        setValue(content);
        setLastSavedValue(content);
      } catch (error) {
        console.error("Error loading note:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadNoteContent();

    return () => {
      setNotesId(null);
    };
  }, []);


  useEffect(() => {
    const pollInterval = 5000; // Poll every 10 seconds
    const poller = setInterval(() => {
      saveChanges(valueRef.current);
    }, pollInterval);

    return () => {
      clearInterval(poller);
    };
  }, [notesId]);


  const saveChanges = async (currentValue) => {
    if (!notesId || currentValue === lastSavedValueRef.current) {
      return;
    }
    try {
      setIsSaving(true);
      const response = await updateNotes(notesId, currentValue);
      if (response?.data) {
        setLastSavedValue(currentValue);
      }
    } catch (error) {
      console.error("Error saving note:", error);
    } finally {
      setIsSaving(false);
    }
  };

  // Render Loading State
  if (isLoading) {
    return (
      <div
        className={`sm:min-h-screen w-full sm:max-w-[750px] 3xl:max-w-[1400px] 4xl:max-w-[1500px] mx-auto transition-all duration-200 border-l ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fcf4f3] border-[#00000016]"
          }`}
      >
        <div
          className={`flex justify-between border-b p-[1px] ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"
            }`}
        >
          <div className="flex p-2 mx-2 min-h-[40px]">Notes</div>
        </div>
        <div className="p-6">
          <NoteSkeleton />
        </div>
      </div>
    );
  }

  // Render Editor
  return (
    <div
      className={`sm:min-h-screen w-full sm:max-w-[750px] 3xl:max-w-[1400px] 4xl:max-w-[1500px] mx-auto transition-all duration-200 border-l ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fcf4f3] border-[#00000016]"
        }`}
    >
      <div
        className={`flex justify-between border-b p-[1px] ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"
          }`}
      >
        <div className="flex p-2 mx-2 min-h-[40px]">Notes</div>
        <div>
          {isSaving && (
            <div className="flex justify-center pr-16 min-h-[40px] items-center">
              Saving...
            </div>
          )}
        </div>
      </div>
      <div className={`pl-0 pb-4 pr-10 h-screen overflow-hidden`}>
        <div className="rounded-lg h-full">
          <ReactQuill
            theme="snow"
            value={value}
            onChange={setValue}
            className={`h-full rounded-xl ${darkTheme ? "bg-[#fcf4f3] !text-black " : "bg-[#fcf4f3] text-black"
              }`}
            placeholder="Write your note here..."
          />
        </div>
      </div>
    </div>
  );
};

export default Notes;
